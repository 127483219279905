  // Offset the position when clicking anchor links
  document.addEventListener("DOMContentLoaded", function() {
    var anchorLinks = document.querySelectorAll('a[href^="#"]');
    for (var i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", function(e) {
        e.preventDefault();
        var target = document.querySelector(this.getAttribute("href"));
        var offset = target.offsetTop - 100; // Offset value to adjust the position
        window.scrollTo({
          top: offset,
          behavior: "smooth" // smooth scrolling effect
        });
      });
    }
  });